$(document).ready(function () {
  console.log("EL LOADED");
  /* Image / Icon accordion block */
  $(".el").hover(
    function () {
      console.log("I WORK");
      $(".el.cobalt .el-content").removeClass("active");
      $(this).find(".el-content").addClass("active");
    },
    function () {
      $(this).find(".el-content").removeClass("active");
    }
  );

  /* Standard default accordion block */
  $(".accordion-item .title").first().addClass("active");
  $(".accordion-item .content").first().addClass("active");

  $(".accordion-item .title").on("click", function (e) {
    e.preventDefault();

    $(".accordion-item .title").removeClass("active");
    $(".accordion-item .content").removeClass("active");

    // Toggle the 'active' class for the title
    $(this).toggleClass("active");

    // Toggle the 'active' class for the corresponding content
    $(this).next(".content").toggleClass("active");
  });
});
