$(document).ready(function () {
  let closeTimer;
  const breakpoint = 900;
  let isMobileView = window.matchMedia(`(max-width: ${breakpoint}px)`).matches;

  function bindHoverBehaviour() {
    // Remove click behaviour to prevent conflicts
    $(".menu-item-has-children > a").off("click");

    // Hover behaviour for desktop
    $(".menu-item-has-children").hover(
      function () {
        $(".sub-menu").removeClass("active"); // Close other submenus
        const $submenu = $(this).find(".sub-menu");
        $submenu.addClass("active");
        $(".site-branding").addClass("active");
        $(this).addClass("hover-active");
        clearTimeout(closeTimer); // Clear any previous timer
      },
      function () {
        const $submenu = $(this).find(".sub-menu");
        closeTimer = setTimeout(function () {
          $submenu.removeClass("active"); // Hide submenu after delay
          $(".site-branding").removeClass("active");
        }, 300);
        $(this).removeClass("hover-active");
      }
    );
  }

  function bindClickBehaviour() {
    // Remove hover behaviour to prevent conflicts
    $(".menu-item-has-children").off("mouseenter mouseleave");

    // Click behaviour for mobile
    $(".menu-item-has-children > a").on("click", function (e) {
      e.preventDefault(); // Prevent navigation

      const $submenu = $(this).siblings(".sub-menu");
      const isActive = $submenu.hasClass("active");

      // Close other submenus
      $(".sub-menu").removeClass("active");
      $(".menu-item-has-children").removeClass("hover-active");
      $(".site-branding").removeClass("active");

      // Toggle the clicked submenu
      if (!isActive) {
        $submenu.addClass("active");
        $(this).parent().addClass("hover-active");
        $(".site-branding").addClass("active");
      }
    });
  }

  function handleMenuBehaviour() {
    const isNowMobileView = window.matchMedia(
      `(max-width: ${breakpoint}px)`
    ).matches;

    if (isNowMobileView !== isMobileView) {
      isMobileView = isNowMobileView; // Update the state
      if (isMobileView) {
        bindClickBehaviour();
      } else {
        bindHoverBehaviour();
      }
    }
  }

  // Initial setup
  if (isMobileView) {
    bindClickBehaviour();
  } else {
    bindHoverBehaviour();
  }

  // Re-bind behaviour on window resize
  $(window).on("resize", function () {
    handleMenuBehaviour();
  });

  // Hamburger menu toggle
  $(".hamburger").on("click", function () {
    $(this).toggleClass("open");
    $(".menu").toggleClass("active");
  });

  // Close submenus when clicking outside the menu
  $(document).on("click", function (e) {
    if (
      !$(e.target).closest(".menu-item-has-children").length &&
      !$(e.target).closest(".hamburger").length
    ) {
      $(".sub-menu").removeClass("active");
      $(".menu-item-has-children").removeClass("hover-active");
      $(".site-branding").removeClass("active");
    }
  });
});
