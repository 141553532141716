$(document).ready(function () {
  console.log("Accordion Items:");

  $(".accordion-item").each(function (i, el) {
    console.log(
      "Index:",
      i,
      "Data-index:",
      $(el).data("index"),
      "Element:",
      el
    );
  });

  let activeItemIndex = null;
  let intervalId = null;
  let timerId = null; // Store timer reference for the active item

  // Function to activate an accordion item by data-index
  function activateAccordionItem(index) {
    // Find the element with the corresponding data-index
    const item = $(".accordion-item[data-index='" + index + "']");

    // If the item doesn't exist (i.e., the index is out of bounds), reset
    if (item.length === 0) {
      console.log(
        "No item with data-index:",
        index,
        "Resetting to first item."
      );
      activateAccordionItem(0); // Reset to the first item
      return;
    }

    // Hide all item-container elements
    $(".item-container").fadeOut();

    // Show the item-container corresponding to the accordion item
    item.addClass("active");
    $('.item-container[data-index="' + index + '"]').fadeIn();

    // Remove 'completed' class from all items and reset their animation state
    $(".accordion-item").removeClass("completed");

    // Remove active class from all items and add it to the current item
    $(".accordion-item").removeClass("active");
    item.addClass("active");

    // Clear the previous timer and start a new one
    clearTimeout(timerId);
    timerId = setTimeout(function () {
      item.addClass("completed");

      // Automatically activate the next item unless it's the last one
      const nextIndex = parseInt(index) + 1;
      if ($(".accordion-item[data-index='" + nextIndex + "']").length > 0) {
        activateAccordionItem(nextIndex);
      } else {
        console.log("Reached the last item. Stopping cycling.");
        clearInterval(intervalId); // Stop the interval since we've reached the end
      }
    }, 20000); // Match the duration of the animation

    activeItemIndex = index; // Store the current active index
    console.log("Activating item with index:", index);
  }

  // Function to cycle to the next item based on data-index
  function cycleAccordionItems() {
    const totalItems = $(".accordion-item").length;

    // If the active item exists, get the current data-index
    if (activeItemIndex === null) {
      activeItemIndex = 0; // Default to first item if none is active
    }

    // Get the next index
    const nextIndex = parseInt(activeItemIndex) + 1;

    // If the next item exists, activate it
    if ($(".accordion-item[data-index='" + nextIndex + "']").length > 0) {
      activateAccordionItem(nextIndex);
    } else {
      console.log("Reached the end of the items. Stopping cycling.");
      clearInterval(intervalId); // Stop automatic cycling
    }
  }

  // Check if the screen size is mobile (you can adjust the width as needed)
  const isMobile = window.innerWidth <= 768;

  // Start auto-cycling only on desktop
  if (!isMobile) {
    intervalId = setInterval(cycleAccordionItems, 20000);
  }

  // Initially activate the first item
  if ($(".accordion-item").length > 0) {
    activateAccordionItem(0); // Always start from the first item
  }

  // Add click event listener to manually activate accordion items
  $(".accordion-item").click(function (event) {
    if ($(event.target).is("a")) {
      return; // Allow links to function normally
    }

    event.preventDefault(); // Prevent page jump for non-link clicks

    const clickedIndex = $(this).data("index"); // Use data-index directly
    clearInterval(intervalId); // Stop automatic cycling on manual click
    activateAccordionItem(clickedIndex);

    // Restart automatic cycling after the manual activation, but only on desktop
    if (!isMobile) {
      intervalId = setInterval(cycleAccordionItems, 20000);
    }
  });
});
